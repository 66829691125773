
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import Footer from '@/components/footer/index.vue'

export default {
    name: 'information',
    components: {
        homeTitlepc,
        Footer,
        
    },

    mounted () {
     
    },

    created(){
        if(this.$route.query.type==1){
            this.name='《国家知识产权局 司法部关于加强新时代专利侵权纠纷行政裁决工作的意见》政策解读'
        }else if(this.$route.query.type==2){
            this.name='《企业知识产权合规管理体系 要求》（GB/T 29490-2023）国家标准解读'
        }else if(this.$route.query.type==3){
            this.name='《专利（申请）权转让合同模板及签订指引》《专利实施许可合同模板及签订指引》解读'
        }
      

    },

    methods: {
        
    },

    data () {
        return {
            config,
            name:'',
           
           
            
        }
    }
}
